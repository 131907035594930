//
// General Styles
// --------------------------------------------------

html {
	margin: 0;
	padding: 0;
	text-size-adjust: 100%;

}

.pl-6,
.px-6 {
	padding-left: 6rem !important;
}

.alignfull {
	width: 100vw;
	left: 50%;
	position: relative;
	transform: translateX(-50%);
	padding: 0;

	figure {
		margin: 0;

		img {
			width: 100%;
			padding: 0;
			margin: 0;
		}
	}
}





body {
	font-family: $font-base;
	font-size: $text-base-size;
	line-height: $text-base-line-height;
	color: $color-text;
	background-color: #ffffff;
	margin: 0;
	padding: 0;
	overflow-x: hidden;
}

* {
	box-sizing: border-box;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

iframe {
	max-width: 100%;
}

a img,
img {
	border: none;
	max-width: 100%;
	height: auto;
}

a {
	color: $color-link;
	cursor: pointer;
	text-decoration: none;

	&:hover {
		outline: none;
		color: $color-link-hover;
		text-decoration: none;

		i {
			color: $color-link-hover;
		}
	}

	&:focus {
		outline: none;
		text-decoration: none;
	}
}

p,
blockquote {
	margin-bottom: 20px;

	&:last-child {
		margin-bottom: 0;
	}
}

blockquote {
	border-left: 5px solid $color-gray-lighter;
	padding: 10px 0 10px 25px;
}

ul {
	margin-top: 25px;
	margin-bottom: 30px;
	// list-style-type: none;
	padding:0;
	// li:before {
	// 	content: '- ';
	// 	margin-right:7px;
	// }
}

ul#menu-bas-de-page li:before {
   display:none;
}

ul#menu-legal li:before {
	display:none;
}

.block_096e06ceb3cdb17f5121bbc871d398a4.acfb_tabs_block ul li:before {
    display:none;
}

ul.gform_fields li:before{
	display:none;
}

h1 {
	font-size: 60px;
	color: $jacq-white;
	font-family: $font-title;
	font-weight: initial;
	margin: 25px 0 30px 0;
	line-height: $h1-line-height;
	max-width: 455px;
	margin-top: 200px;
	margin-bottom: 200px;
	margin-left: 320px;
}

h2 {
	color: $color-primary;
	font-family: $font-title;
	font-weight: initial;
	color: initial;
	font-size: 28px;
}

h3 {
	font-size: 24px;
	color: $color-primary;
	font-family: $font-title;
	font-weight: bold;
	margin: 20px 0 15px 0;
	line-height: $title-base-line-height;
}

h4 {
	font-size: 18px;
	color: $color-primary;
	font-family: $font-title;
	font-weight: bold;
	margin: 20px 0 15px 0;
	line-height: $title-base-line-height;
}

h5 {
	font-size: 14px;
	color: $color-primary;
	font-family: $font-title;
	font-weight: bold;
	margin: 20px 0 15px 0;
}

h6 {
	font-size: 12px;
	color: $color-primary;
	font-family: $font-title;
	font-weight: bold;
	margin: 20px 0 15px 0;
}

table {
	margin-top: $margin-base;
	margin-bottom: 15px;
	border: inherit;
	border-color: inherit;
	border-spacing: 0;
	border-collapse: collapse;

	tr {
		border: inherit;
		border-color: inherit;

		td {
			border-top: 1px solid $color-gray-lighter !important;
			padding: 1rem !important;
			vertical-align: middle !important;
		}
	}
}

.wp-block-button__link {
	color: $jacq-black;
	background-color: transparent;
	border-radius: 9999px;
	box-shadow: none;
	text-decoration: none;
	padding: calc(0.667em + 2px) calc(1.333em + 2px);
	font-size: 1.125em;
	border: 1px solid $jacq-black;

	&:hover {
		background-color: $jacq-black;
		color: $jacq-white;
	}
}

address {
	font-style: normal;
}

.select2 {
	width: 100% !important;
}

.select2-dropdown {
	border-color: $color-primary;
	border-radius: 0;
}

.select2-results__option {
	padding: 8px 20px;
	background-color: white;
	color: $color-text;
	font-size: 18px;
	transition: $transition-base;

	&.select2-results__option--highlighted {
		background-color: white !important;
		color: $color-text !important;
	}

	&:hover {
		background-color: lighten($color-primary, 20%) !important;
		color: $color-text !important;
	}

	&.select2-results__option--selected {
		background-color: $color-primary !important;
		color: $color-text !important;
	}
}

.select2 {

	&.select2-container--open {

		.select2-selection__rendered {
			color: $color-gray-dark !important;
		}

		.selection {

			.select2-selection--single {

				.select2-selection__arrow {
					transform: rotate(180deg) translateY(50%);
				}
			}
		}
	}

	.select2-selection__arrow {
		top: 50% !important;
		right: 15px !important;
		transform: translateY(-50%);
		width: auto !important;
		height: auto !important;
		transition: $transition-base;

		&::after {
			content: "\f107";
			font-family: 'Font Awesome 5 Pro';
			font-weight: 100;
			color: $color-text;
			font-size: 22px;
		}
	}

	.select2-selection__arrow b {
		display: none;
		visibility: hidden;
	}
}

.select2-selection--single {
	background-color: transparent !important;
	height: auto !important;
	border-radius: 0px !important;
	border: none !important;
	border-bottom: 2px solid $color-primary !important;
	color: $color-text;

	.select2-selection__choice {
		background-color: $color-primary !important;
		color: $color-text !important;
		border-color: $color-primary !important;
		vertical-align: middle !important;
		margin: 6px !important;
		padding: 0 !important;
	}

	.select2-selection__choice__remove {
		color: $color-text !important;
		border-color: $color-text !important;
		vertical-align: middle !important;
		padding: 5px 8px !important;
		position: static !important;

		&:hover {
			border-right: 1px solid $color-text !important;
			background-color: darken($color-primary, 10%) !important;
		}
	}

	.select2-selection__choice__display {
		padding: 0 6px !important;
	}
}

.select2-selection__rendered {
	padding: 15px 20px !important;
	font-size: 18px;
	line-height: normal !important;
	color: $color-gray-darker !important;
	transition: all .2s linear;
	width: 100%;
}

.gform_validation_errors {
	padding: 0;
	color: $color-red;
	margin-bottom: 25px;
	font-size: 14px;

	.gform_submission_error {
		color: $color-red;
		font-size: 24px;
		line-height: normal;
		margin-bottom: 10px;
	}

	ol {
		padding: 0;
		list-style-position: inside;
	}

	.gform_validation_error_link {
		color: $color-red;
	}
}

.validation_message {
	color: $color-red;
	clear: both;
}

form {

	.gfield--type-honeypot {
		display: none !important;
	}

	.gform_button_select_files {
		margin: 15px 0;
		transform: inherit;
		background-color: transparent;
		&:hover {
			border: 1px solid #3c3c3b !important;
		}
	}

	.gform_body {
		display: table;
		width: 100%;
	}

	.gform_footer {
		margin-top: 20px;
		display: flex;
		align-items: flex-start;
		justify-content: flex-end;
	}

	.gform_hidden {
		display: none !important;
	}

	.gfield_required {
		margin-left: 5px;
		color: $color-red;
		font-weight: bold;
		transition: all .2s linear;
	}

	.gform_fields {
		margin: 0;
		padding: 0;
		list-style: none;
		// display: grid;
		grid-template-columns: repeat(2, 1fr);
		column-gap: 25px;
		row-gap: 25px;

		.gfield--type-fileupload {
			margin-top: 30px !important;
		}

		.uploader-btn {
			display: flex;
			align-items: center;
			justify-content: flex-start;

			.uploader-filename {
				margin-right: 60px;
				min-width: 150px;
			}

			button {
				background-color: $color-primary;
				color: $color-text;
				border: 2px solid $color-primary;
				box-shadow: none;

				&:hover {
					background-color: $color-link-hover;
					color: $color-text;
					border: 2px solid $color-primary !important;
				}

				&:focus {
					border: 2px solid $color-link-hover !important;
				}
			}
		}

		.gfield_list_group {

			.gfield_list_icons {
				margin-top: 10px;
			}
		}

		.gfield {
			margin: 0;
			padding: 0;
			position: relative;
			font-size: 18px;
			line-height: normal;
			//margin-bottom: 15px;

			&.gfield--width-full {
				grid-column: span 2;
			}

			&.gfield_visibility_hidden {
				display: none;
			}

			&.hidden_label {
				.gfield_label {
					display: none;
				}
			}

			&:focus-within,
			&.not-empty {

				label,
				.gfield_label {
					transform: translateY(-1.5em);
					font-size: 0.8em;
					color: transparent;
				}

				.gfield_required {
					color: transparent;
				}
			}

			&.field_sublabel_above {
				margin-bottom: 20px;
			}

			&.file-input {
				display: none;
			}

			&.gfield_error {

				input,
				textarea {
					border-color: $color-red;
				}
			}

			.gfield_label_before_complex {
				display: none;
			}

			.ginput_complex {
				display: inline-block;
				width: 100%;

				span {
					display: inline-block;
					width: 100%;

					&.name_first,
					&.ginput_left {
						width: 50%;
						padding-right: 15px;
						float: left;
					}

					&.name_last,
					&.ginput_right {
						width: 50%;
						padding-left: 15px;
						float: left;
					}

					&.address_line_1 {
						margin-bottom: 20px;
					}
				}
			}

			.gchoice {
				display: flex;
				gap: 15px;
    			align-items: baseline;
				input {
					margin: 0;
				}
				label {
					color: $color-gray-light;
					position: static;
					height: auto;
					line-height: 1.2em;
					transform: inherit;
					font-size: 18px;
					a {
						font-weight: bold;
					}
				}

				// width: fit-content;
				// transition: all .2s linear;

				// &:not(:last-child) {
				// 	margin-bottom: 4px;
				// }

				// input {
				// 	visibility: hidden;
				// 	margin: 0;
				// 	margin-right: 0;
				// }

				// label {
				// 	padding-left: 5px;
				// 	font-weight: 400;
				// 	position: relative;
				// 	cursor: pointer;
				// 	left: initial;
				// 	top: initial;
				// 	transition: all .2s linear;

				// 	&::before {
				// 		position: absolute;
				// 		top: 50%;
				// 		left: -20px;
				// 		transform: translateY(-50%);
				// 		content: "";
				// 		width: 16px;
				// 		height: 16px;
				// 		border-radius: 50%;
				// 		border: 2px solid $color-primary;
				// 		background-color: transparent;
				// 		transition: all .2s linear;
				// 	}
				// }

				// .gfield-choice-input:checked~label {
				// 	font-weight: 600;

				// 	&::before {
				// 		background-color: $color-primary !important;

				// 	}
				// }

				// &:hover {

				// 	label {
				// 		&::before {
				// 			background-color: $color-primary;
				// 		}
				// 	}
				// }
			}
		}
	}

	label,
	.gfield_label {
		font-size: inherit;
		line-height: inherit;
		color: transparent;
		height: 0;
		position: absolute;
		left: 16px;
		top: 16px;
		transition: all .2s linear;
		transform-origin: left top;
	}

	.input-group-addon {
		background-color: $color-gray-darker;
		color: $color-primary;
		border: 0;
	}

	fieldset {
		border: none;
	}

	select {
		padding: 8px 10px;
		background-color: white;
		height: 42px;
		color: $color-gray;
		width: 100%;
		border: 0;
		border: 1px solid $color-gray-lighter;
	}

	select[multiple] {
		height: auto;
	}

	input,
	textarea {
		width: 100%;
		border: 0;
		border-bottom: 2px solid $color-primary;
		color: $color-gray-darker;
		padding: 18px 15px 17px 0;
		appearance: none;
		resize: none;
		background-color: transparent;
		font-size: 18px;
		line-height: normal;
		font-family: inherit;

		&::placeholder {
			font-size: inherit;
			color: $color-gray-light;
		}

		&:focus {
			outline: none !important;
			box-shadow: none !important;
			border: 0 !important;
			border-bottom: 2px solid $jacq-green !important;
			color: $jacq-green;

			&::placeholder {
				color: $jacq-green;
				opacity: 1;
			}
		}


	}

	input[type="checkbox"],
	input[type="radio"] {
		width: auto;
		appearance: inherit;
	}

	input[type="checkbox"] {
		appearance: checkbox;
	}

	input[type="radio"] {
		appearance: radio;
	}

	.gfield_description {
		margin-top: 0;
		margin-bottom: 10px;
		font-size: 14px;
		font-style: italic;
	}
}

button,
button[type="submit"],
.product .cart .single_add_to_cart_button,
#error-template .button,
.woocommerce a.button {
	display: inline-block;
	width: auto;
	color: $color-text;
	background-color: $color-link;
	border: 0;
	border-radius: 0;
	padding: 10px 20px;
	font-size: $text-base-size;
	text-decoration: none;
	cursor: pointer;
	line-height: inherit;
	text-align: center;
	transition: all .2s linear;

	&.success {
		background-color: $color-green;
		color: $color-text;

		&:hover {
			color: $color-text;
			background-color: lighten($color-green, 5%);
		}
	}

	&.error {
		background-color: $color-red;
		color: $color-text;

		&:hover {
			color: $color-text;
			background-color: lighten($color-red, 5%);
		}
	}

	&:hover {
		color: $color-text;
		background-color: $color-link-hover;
		text-decoration: none;
		border: 0 !important;
	}

	&:focus {
		outline: none !important;
		text-decoration: none;

	}
}

.container,
.container-fluid {
	position: relative;
}

.wow {
	visibility: hidden;
}

.slick-list {
	overflow: hidden;

	.slick-slide {
		overflow: hidden;

		&:focus {
			outline: none;
		}
	}
}

.slick-slider {
	visibility: hidden;
	margin-bottom: 0;
}

.slick-initialized {
	visibility: visible !important;
}

.slick-arrow {
	z-index: 1;
	height: auto;
	width: auto;

	&:before {
		font-family: "Font Awesome 5 Pro";
		font-weight: 900;
		color: #ffffff;
		font-size: 45px;
		font-weight: 100;
	}

	&.slick-prev {
		left: 30px;

		&:before {
			content: "\f104";
		}
	}

	&.slick-next {
		right: 30px;

		&:before {
			content: "\f105";
		}
	}
}

.slick-dots {
	bottom: 15px;

	li {
		width: auto !important;
		height: auto !important;
		margin: 0 5px !important;
		display: block !important;
		float: left !important;

		button {
			width: 12px !important;
			height: 12px !important;
			padding: 0 !important;
			margin: 0 !important;
			background-color: #ffffff !important;
			border-radius: 100% !important;
			opacity: 0.7 !important;
			padding: 0 !important;

			&:before,
			&:after {
				display: none !important;
			}
		}

		&:first-child {
			margin-left: 0 !important;
		}

		&:last-child {
			margin-right: 0 !important;
		}

		&.slick-active {
			button {
				opacity: 1 !important;
			}
		}

		&:only-child {
			display: none !important;
		}
	}
}

.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
}

.page-section {
	padding-top: $margin-base;
	padding-bottom: $margin-base;
}

.no-padding-top {
	padding-top: 0;
}

.no-padding-bottom {
	padding-bottom: 0;
}

.no-margin-top {
	margin-top: 0;
}

.no-margin-bottom {
	margin-bottom: 0;
}

#main-content {

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		&:first-child {
			margin-top: 0;
		}
	}
}

.acf-map {
	width: 100%;
	height: 700px;
	border: 0;
	border: 0;
}

.highlight {
	color: #ffffff;
	background-color: $color-primary;
}

#error-template {
	.error-details {
		margin-bottom: 30px;
	}

	.error-actions {
		display: flex;
		gap: 50px;
		align-items: center;
		justify-content: center;
		.button {
			margin: 0;
			&:hover {
				i {
					color: #ffffff;
				}
			}
		}
	}
}

#post-share {
	.social-share {
		padding: 0;
		margin: 0;
		list-style: none;

		li {
			display: inline-block;
			margin-right: 30px;

			&:last-child {
				margin-right: 0;
			}

			a {
				i {
					font-size: 25px;
				}
			}
		}
	}
}

#page-header {
	position: relative;

	&.standard {
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		overflow: hidden;
		color: #ffffff;
		height: 500px;

		.video {
			position: absolute;
			top: 50%;
			left: 50%;
			min-width: 100%;
			min-height: 100%;
			width: auto;
			height: auto;
			z-index: -1;
			transform: translateX(-50%) translateY(-50%);
		}
	}

	&.slider {
		.slide {
			position: relative;
			height: 500px;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			overflow: hidden;
			color: #ffffff;
		}
	}

	.link {
		margin-top: 30px;
	}

	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.page-header-content {
		position: absolute;
		left: 0;
		width: 100%;
		top: 50%;
		transform: translateY(-50%);

		.title {
			margin: 0;
		}
	}

}

.loading-spinner {
	width: 50px;
	height: 50px;
	margin: 0 auto;
	float: none;
	background-image: url('../images/ajax-loader.svg');
	background-size: 100%;
	background-position: center;
	background-repeat: no-repeat;
}

#pagination {
	text-align: center;

	.navigation {
		ul {
			padding: 0;
			margin: 0;

			li {
				display: inline-block;

				a {
					color: #ffffff;
					text-decoration: none;
					background-color: $color-gray-dark;
					cursor: pointer;
					padding: 5px 13px;
					border-radius: 5px;
					display: block;

					&:hover {
						background-color: $color-primary;
					}
				}

				&.active {
					a {
						background-color: $color-primary;
					}
				}
			}
		}
	}
}

#cookie-notice {
	.button {
		font-size: 14px;
		padding: 5px 10px;
	}
}

/* WordPress */
.wp-caption,
.gallery-caption {
	color: #666;
	font-size: 13px;
	font-size: 0.8125rem;
	font-style: italic;
	margin-bottom: 1.5em;
	max-width: 100%;
}

.wp-caption img[class*="wp-image-"] {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.wp-caption .wp-caption-text {
	margin: 0.8075em 0;
	font-size: 14px;
}

.bypostauthor>.comment-body>.comment-meta>.comment-author .avatar {
	border: 1px solid #333;
	padding: 2px;
}

.alignleft {
	display: inline;
	float: left;
	margin-right: 1.5em;
}

.alignright {
	display: inline;
	float: right;
	margin-left: 1.5em;
}

.aligncenter {
	clear: both;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

img.alignleft {
	float: left;
	margin-right: 1.5em;
}

img.alignright {
	float: right;
	margin-left: 1.5em;
}

.main.page-activite .wp-block-columns .wp-block-column h2 {
	line-height: 1.2em;
}

// @media (min-width:1300px) {
// 	.page-template section#main >#main-content {
// 		margin-top: 25px;
// 	}	
// }


/*****************
 * VIDEO SECTION *
 *****************/
#homeBanner {
	img {
		display: block;
		margin: 0 auto;
	}
}
#homeVideo {
	width: 100%;
	//max-width: 1920px;
	position: relative;
	top: 0;
	left: 0;
	display: block;
	width: 100%;
	height: 100%;
	max-height: 590px;
	-o-object-fit: cover;
	object-fit: cover;
	// margin-top: 25px;
	margin-left: auto;
	margin-right: auto;
	pointer-events: none;
}

#hook {
	width: 100%;
	max-width: 1920px;
	margin: auto;
	display: flex;
	align-items: stretch;
	z-index: 2;
	position: relative;




	.hookleft {
		background-color: #3c3c3b;
		width: calc(100% - 647px);
		position: relative;
		left: 0;
		height: 596px;
		position: relative;

		display: flex;
		align-items: center;

		.innerHook {
			left: -100%;
			top: 0;
			position: absolute;
			width: 100%;
			height: 100%;
			background-color: #3c3c3b;
		}

		.innerHookBottom {
			position: absolute;
			bottom: 0;
		}

		.container {
			//width: calc(1170px - 647px);

			h1 {
				margin: 0;
			}
		}
	}

}

.hookright {
	width: 100%;
	// max-width: 647px;
	background-color: $jacq-green;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	position: relative;


	a {
		color: $jacq-black;
		display: inline-flex;
		margin-bottom: 35px;
		margin-left: 30px;
		position: relative;
		width: fit-content;
		overflow: hidden;

		&:hover::before {
			width: 50%;
			left: 100%;
			transition: left 1s, width 1s;
			-webkit-transition: left 1s, width 1s;
			-moz-transition: left 1s, width 1s;
			-ms-transition: left 1s, width 1s;
			-o-transition: left 1s, width 1s;
		}

		&::before {
			content: "";
			width: 100%;
			background: $jacq-black;
			height: 1px;
			position: absolute;
			bottom: 4px;
			left: 20px;
			transition: left 2s, width 2s;
			-webkit-transition: left 2s, width 2s;
			-moz-transition: left 2s, width 2s;
			-ms-transition: left 2s, width 2s;
			-o-transition: left 2s, width 2s;
		}

	}


}


.subhook {
	width: 100%;
	z-index: 0;
}

.home #hook {
	.hookright a.homeLink {
		&::before {
			left: 35px;
		}
		&:hover::before {
			left: 100%;
		}
		svg {
			margin-right: 15px;
		}
	}
}
// HOME PAGE
@media (min-width:992px){
	.home #hook {
		max-width: 100%;
		.hookleft {
			width: 40%;
			max-height: 315px;
			height: 315px;
			.container {
				max-width: 460px;
				margin-left: auto;
				margin-right: auto;
			}
		}
		.hookright {
			max-width: none;
			width: 60%;
			.picture-homepage {
				position: absolute;
				top: 0;
				right: 0;
				height: auto;
				max-height: none;
				width: 100%;
				max-width: 100%;
				max-height: 380px;
			}
			.homeLink {
				margin-top: 120px;
				margin-bottom: auto;
			}
		}
	}
}
@media (min-width:1400px){
	.home #hook {
		.hookleft, .hookright {
			width: 50%;
		}
		.hookleft .container {
			margin-left: auto;
			margin-right: 120px;
		}
		.hookright .picture-homepage {
			max-width: 100%;
			width: 100%;
			min-height: 380px;
		}
	}
}
.home #hook .hookleft .container, .teampage#hook:not(.technology) .hookleft .container  {
	h1, h2, h3, h4, h5, h6, p {
		// color: $jacq-green!important;
		color: white !important;
	}
}

#hook .container {
	h1, h2, h3, h4, h5, h6, p {
		line-height: 1.2em;
	}
}

#home-intro {
	background-color: $jacq-gray-light;
	margin: -32px 0 0 0;
	padding: 200px 0 100px;
	.content-text {
		font-size: 32px;
		color: $color-gray-dark;
		line-height: 1.1em;
		max-width: 100%;
		width: 850px;
		.button {
			font-size: 16px;
			margin: 30px 0 0 0;
			transform: inherit;
		}
	}
}


// TEAM PAGE
.teampage#hook .container, .officepage#hook .container {
	@media (min-width:992px) {
		margin-right: 20px;
	}
	@media (min-width:1200px){
		margin-right: 126px;
	}
	@media(min-width:1920px){
		margin-right: 130px;
	}
}

.officepage.referencesPage#hook {
	.container {
		@media (min-width:992px) {
			margin-right: 20px;
		}
		@media (min-width:1200px){
			margin-right: 126px;
		}
		@media(min-width:1920px){
			margin-right: 130px;
		}
	}
}


// .teampage {

// 	.hookleft {
// 		background-color: #3c3c3b;
// 		position: relative;
// 		left: 0;
// 		height: 596px !important;

// 		.container {
// 			width: auto!important;
// 			max-width: 460px;

// 			h1 {
// 				margin: 0;
// 			}
// 			color: #fff;
// 		}
// 	}

// 	.hookright {
// 		max-width: 946px;

// 		img {
// 			position: absolute;
// 			top: 0;
// 			max-height: 688px;
// 		}
// 	}
// }

.teampage.technology, .officepage.referencesPage {
	.hookleft .container, h1 {
		color: inherit;
	}
}

.officepage.referencesPage.contactPage#hook  {
	.innerHookBottom {
		background-color: transparent!important;
	}
}

#page-contact #post-content {
	padding-top: 60px;
	p a {
		color: #1d1d1d;
	}
}

.gfield_required.gfield_required_asterisk {
	display: none;
}

// OFFICE PAGE
.officepage#hook {
	.hookright {
		.container {
			margin-bottom: 8%;
		}
	}
}

@media (min-width:992px){
	.officepage#hook {
		// max-width: 100%;
		.hookleft {
			width: 40%;
			height: 590px;
			img {
				width: 100%;
				max-height: 590px;
				object-fit: cover;
			}
		}
		.hookright {
			max-width: none;
			width: 60%;
			.container {
				max-width: 480px;
				margin-left: 8%;
				margin-bottom: 8%;
			}
		}
		.hookleft, .hookright {
			max-height: 490px;
		}
	}
	.officepage.referencesPage#hook {
		.innerHookBottom {
			background-color: #f2f2f2!important;
			bottom: 0!important;
		}
		.hookleft {
			.container {
				max-width: 460px;
				width: auto;
			}
		}
		.hookleft, .hookright {
			width: 50%;
		}
	}
	.teampage#hook {
		// max-width: 100%;
		.hookright {
			width: 40%;
			height: 590px;
			img {
				width: 100%;
				max-height: 590px;
				object-fit: cover;
			}
		}
		.hookleft {
			max-width: none;
			width: 60%;
			.container {
				max-width: 480px;
				margin-left: 8%;
				margin-bottom: 8%;
			}
		}
		.hookleft, .hookright {
			max-height: 490px;
		}
	}
}
@media (min-width:1400px){
	.page-template-template-office {
		section.main {
			margin-top: 200px;
		}
	}
	.officepage#hook {
		.hookleft, .hookright {
			width: 50%;
		}
		.hookright .picture-homepage {
			max-width: 100%;
			width: 100%;
		}
		&:not(.referencesPage) .hookleft {
			align-items: start;
		}
		.hookleft img {
			min-height: 590px;
		}
	}
	.teampage#hook {
		.hookleft, .hookright {
			width: 50%;
		}
		.hookleft .picture-homepage {
			max-width: 100%;
			width: 100%;
		}
		&:not(.referencesPage) .hookright {
			align-items: start;
		}
		.hookright img {
			min-height: 590px;
		}
	}
}

.bureau-bottom-block {
	margin-bottom: 0;
	figure img {
		max-height: 400px;
	}
	h2 {
		line-height: 1.2em;
	}
}

.officepage {

	.hookleft {
		background-color: $jacq-black !important;
		position: relative;
		left: 0;

		.innerHook {
			background-color: $jacq-black !important;
		}

		.innerHookBottom {
			background-color: $jacq-black !important;
			height: 100px;
			bottom: -100px !important;
			width: 100%;
		}
	}

	.hookright {
		background: $jacq-gray-light;
		h1 {
			color: initial;
			max-width: fit-content;
			margin-top: 200px;
			margin-bottom: auto;
			margin-left: initial;
		}

		img {
			position: absolute;
			top: 0;
			width: 100%;
			max-height: 590px;
			object-fit: cover;
		}
	}
}

.subhook {
	.container {
		display: flex;
		width: 100%;
		max-width: 1920px;
		padding: 0;
		justify-content: flex-end;

		.innerSubHook {
			max-width: 946px;
			background: $jacq-gray-light;
			width: 100%;
			padding: 5%;

			h2 {
				font-weight: initial;
				color: initial;
				font-size: $text-hook;
			}

			p {
				font-size: $text-hook-p;
			}
		}

	}

}

//Contact PAGE
@media (min-width:992px){
	.officepage.contactPage {
		.hookright {
			background-color: #fff!important;
		}	
		.hookleft .container {
			min-width: 460px;
		}	
	}
}
.officepage.contactPage {
	.hookleft {
		.container {
			h1, h2, p {
				color: $jacq-green;
			}
		}
	}
	.hookright {
		.contact-hover-superposition {
			position: absolute;
			opacity: 0;
			z-index: 1;
			transition: opacity .3s ease-in-out;
		}
		&:hover {
			.contact-hover-superposition {
				opacity: 1;
			}
		}
	}
}


// MAIN

.main {
	margin-top: 150px;
}

// tabs styling

.acfb_tabs_block ul {
	display: flex;
	flex-direction: column;
	width: 40%;
}

.acfb_tabs_block {
	display: flex;
}

.acfb_tabs_block .acfb_tab_content {
	padding: 20px;
	width: 60%;
}

.acfb_tabs_block ul li,
.acfb_tab_content {
	background: transparent !important;
}

.acfb_tabs_block .acfb_tab_content {
	padding: 0px 20px !important;
}

.acfb_tabs_block ul li {
	padding-bottom: 25px;
}

.acfb_tabs_block ul li.active a {
	font-size: 44px !important;
	color: $jacq-black !important;

}

.acfb_tabs_block ul li a {
	font-size: 28px !important;
	color: $color-primary !important;
	text-align: left !important;
	padding-left: 0 !important;
	transition: font-size .5s;
	-webkit-transition: font-size .5s;
	-moz-transition: font-size .5s;
	-ms-transition: font-size .5s;
	-o-transition: font-size .5s;
}

.showMoreACF {
	color: $jacq-black;
	width: 47px;
	text-align: center;
	border: 1px solid $jacq-black;
	margin-top: 30px;
	border-radius: 30px;
	padding: 10px;
	cursor: pointer;

	&:hover {
		background: $jacq-black;
		color: $jacq-white;
	}
}

.visibleNone {
	display: none;
}

.activeBtn {
	background: $jacq-black;
	color: $jacq-white;
}

.picture-homepage {
	width: 100%;
	height: 100%;
	max-height: 293px;
	max-width: 400px;
	object-fit: cover;
}


.picture-homepage {
	width: 100%;
	height: 100%;
	max-height: 293px;
	max-width: 400px;
	object-fit: cover;
}

#fast-access {

	display: flex;
	justify-content: center;
	min-height: 600px;
	align-items: center;

	a {
		display: flex;
		flex-direction: column;
		text-align: center;
		min-width: 100px;
		align-items: center;
		color: $jacq-black;
		font-size: 44px;
		margin: 80px;

		.fast-access-img {
			width: 56px;
			height: 56px;
			scale: 1;
			opacity: 0.5;
			transition: opacity 1s, scale 1s;
			-webkit-transition: opacity 1s, scale 1s;
			-moz-transition: opacity 1s, scale 1s;
			-ms-transition: opacity 1s, scale 1s;
			-o-transition: opacity 1s, scale 1s;
			transform-origin: top;
		}

		span {
			opacity: 0;
			margin-top: 40px;
			transition: opacity .5s, margin-top .5s;
			-webkit-transition: opacity .5s, margin-top .5s;
			-moz-transition: opacity .5s, margin-top .5s;
			-ms-transition: opacity .5s, margin-top .5s;
			-o-transition: opacity .5s, margin-top .5s;
		}

		&:hover {

			.fast-access-img {
				opacity: 1;
				scale: 1.5;
			}

			span {
				opacity: 1;
				margin-top: 70px;
			}
		}

	}

}

#quick-access {
	margin: 6rem auto 10rem;
	margin-top: calc(5rem + 65px);
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		li {
			padding-bottom: 20px;
			&::before {
				content: none;
			}
			a {
				display: block;
				text-decoration: none;
				padding: 15px 20px;
				line-height: 16px;
				outline: none;
				cursor: pointer;
				font-size: 28px!important;
				color: #b5b5b5!important;
				text-align: left!important;
				padding-left: 0!important;
				&:hover {
					font-size: 44px!important;
					color: #3c3c3b!important;
				}
			}
		}
	}
}



#team {

	height: 480px;
	display: flex;
	align-items: center;

	.hook {
		width: 100%;
		max-width: 487px;
		font-size: $text-hook;
		line-height: $h1-line-height;
	}
}

.fade-in {
	animation: fadeIn ease 0.5s;
	-webkit-animation: fadeIn ease 0.5s;
	-moz-animation: fadeIn ease 5s;
	-o-animation: fadeIn ease 5s;
	-ms-animation: fadeIn ease 5s;
}


@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@-moz-keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@-o-keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@-ms-keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.actuBox {
	cursor: pointer;
	color: #B5B5B5;
	display: block;
	&:hover {
		color: #3c3c3b;
		.actuBoxImgContent {
			filter: grayscale(0);
		}
		.actuBoxBtn {
			border: 1px solid #3c3c3b;
			background: #3c3c3b;
			text#_ {
				fill: white;
			}
		}
	}
}


.actuBoxImg {
	width: 100%;
    // max-height: 300px;
    // overflow: hidden;

	.actuBoxImgContent {
		width: 100%;
		height: 100%;
		object-fit: cover;
		filter: grayscale(1);
	}
}



.actuBoxContent {
	display: flex;
	flex-wrap: wrap;
	background: #FFF;
	margin-top: 23px;
	// font-size: 19px;
	// line-height: 26px;
	line-height: 1.5em;

	&.open {
		.actuBoxBtn {
			transform: rotate(90deg);
		}
	}

	.actuBoxTitle {
		font-size: 19px;
		margin-bottom: 5px;
	}

	.actuBoxBtnContainer {
		width: 100%;
	}
	.actuBoxBtn {
		width: 47px;
		text-align: center;
		border: 1px solid $jacq-gray;
		margin-top: 15px;
		border-radius: 30px;
		padding: 10px;
		cursor: pointer;
		height: 47px;
	}

	.innerArrow {
		svg {
			transform: rotate(90deg);
			-webkit-transform: rotate(90deg);
			-moz-transform: rotate(90deg);
			-ms-transform: rotate(90deg);
			-o-transform: rotate(90deg);
			text#_ {
				fill: #3C3C3B;
				
			}
			&:not(.rotated){
				text#_ {
					fill: #B5B5B5;
				}
			}
		}
	}

	.actuBoxLeftInner {
		min-width: 105px;
	}

	.actuBoxMore {
		width: 100%;
		margin-top: 50px;
		line-height: 26px;
	}
}

#allActus {
	.row {
		position: relative;
		// min-height: 1000px;
		margin-bottom: 100px;
	}

}

.rotated {
    transform: rotate(270deg)!important;
    -webkit-transform: rotate(270deg)!important;
    -moz-transform: rotate(270deg)!important;
    -ms-transform: rotate(270deg)!important;
    -o-transform: rotate(270deg)!important;
    cursor: pointer;
}

div.row:nth-child(odd) {

	.actuBox:nth-child(1) {
		width: 25%;
		position: absolute;
		left: 0;
		top: 0;
	}

	.actuBox:nth-child(2) {
		width: 35%;
		position: absolute;
		left: 0;
		bottom: 0;
	}

	.actuBox:nth-child(3) {
		width: 50%;
		position: absolute;
		right: 0;

		.actuBoxImg {
			max-width: 626px;
			max-height: 434px;

			.actuBoxImgContent {
				width: 100%;
				max-width: 626px;
				max-height: 434px;
			}
		}
	}


}

div.row:nth-child(even) {

	.actuBox:nth-child(2) {
		width: 25%;
		position: absolute;
		right: 0;
		top: 0;
	}

	.actuBox:nth-child(3) {
		width: 35%;
		position: absolute;
		right: 0;
		bottom: 0;
	}

	.actuBox:nth-child(1) {
		width: 50%;
		position: absolute;
		left: 0;

		.actuBoxImg {
			max-width: 626px;
			max-height: 434px;

			.actuBoxImgContent {
				width: 100%;
				max-width: 626px;
				max-height: 434px;
			}
		}
	}


}

.showMore {
	display: block!important;
	opacity: 1;
}

.hidden {
	display: none;
	opacity: 1;
}

.upTo {
	z-index: 2;
}

#showMore,
.button,
input[type="submit"] {
	color: $jacq-black;
	margin-left: 50%;
	transform: translate(-50%);
	text-align: center;
	border: 1px solid $jacq-black;
	border-radius: 30px;
	padding: 12px 26px 8px;
	display: block;
	line-height: 1em;
	width: fit-content;
	cursor: pointer;
	&:hover {
		background: $jacq-black;
		color: $jacq-white;
	}
}

.page-template-template-references #teamRow {
	.row:first-of-type {
		padding-top: 2rem;
	}
	// .row#director {
	// 	margin-bottom: 50px;
	// }
	// .referenceSelector {
	// 	margin-top: auto;
	// }
	#members {
		.row:first-child {
			margin-top: 0;
			padding-top: 0;
		}
	}
}

#teamRow {
	.row {

		margin: 0;
		margin-bottom: 100px;
		&:first-of-type {
			margin-top: 3rem;
		}
	}

	#director {
		flex-direction: row-reverse;
		justify-content: space-between;

		div.objectBox:nth-child(1) {
			width: 50%;
			margin-left: 10%;
		}

	}

	.objectBox {
		width: 33%;
		color: $jacq-gray;
		cursor: pointer;

		img {
			filter: grayscale(1);
			-webkit-filter: grayscale(1);
			width: 100%;
			object-fit: cover;
			height: 100%;
			max-width: 626px;
			max-height: 626px;
		}

		.objectBoxContent {
			margin-top: 15px;
		}

		.objectBoxBtn {
			width: 47px;
			text-align: center;
			border: 1px solid $jacq-gray;
			margin-top: 15px;
			border-radius: 30px;
			padding: 10px;
			cursor: pointer;
			height: 47px;
		}

		&:hover {
			color: $jacq-black;

			.objectBoxBtn {
				border: 1px solid $jacq-black;
				background: $jacq-black;

				text#_ {
					fill: $jacq-white;
				}

			}

			img {
				filter: grayscale(0);
				-webkit-filter: grayscale(0);
			}
		}




	}

	.s1 {
		.objectBox {
			width: 23%;
			margin-right: 2%;
		}

		div.objectBox:nth-child(3) {
			width: 23%;
			margin-left: 10%;
		}
	}

	.s2 {
		flex-direction: row-reverse;

		.objectBox {
			width: 23%;
			margin-right: 2%;
		}

		div.objectBox:nth-child(3) {
			width: 40%;
			margin-right: 12%;
		}

		div.objectBox:nth-child(1) {
			margin-right: 0%;
		}
	}

	.s3 {
		justify-content: flex-end;
		flex-direction: row-reverse;

		.objectBox {
			width: 23%;
			margin-right: 2%;
		}

		div.objectBox:nth-child(1) {
			width: 23%;
			margin-left: 27%;
			margin-right: 0;
		}
	}

	.s4 {

		flex-direction: row-reverse;

		.objectBox {
			width: 23%;
			margin-right: 2%;
		}

		div.objectBox:nth-child(2) {
			width: 33%;
			margin-right: 2%;
		}

		div.objectBox:nth-child(1) {
			width: 23%;
			margin-right: 0%;
		}

	}

	.s5 {

		flex-direction: row-reverse;

		.objectBox {
			width: 23%;
			margin-right: 2%;
		}

		div.objectBox:nth-child(2) {
			width: 40%;
			margin-right: 2%;
		}

		div.objectBox:nth-child(1) {
			width: 33%;
			margin-right: 0%;
		}

	}


}

.single #singleContent {
	.row .col-12 h1 {
		max-width: none;
	}
}

//Single Team Member

#singleContent {
	margin-top: 265px;
	margin-bottom: 95px;

	a {
		color: $jacq-black;
		position: relative;
		overflow: hidden;

		&::before {
			content: "";
			width: 100%;
			background: $jacq-black;
			height: 1px;
			left: 0;
			position: absolute;
			bottom: 4px;
			transition: left 2s, width 2s;
			-webkit-transition: left 2s, width 2s;
			-moz-transition: left 2s, width 2s;
			-ms-transition: left 2s, width 2s;
			-o-transition: left 2s, width 2s;
		}

		&:hover::before {
			left: 100%;
			width: 0%;
		}
	}

	.backLink {
		svg {
			width: 150px;
			transform: rotate(180deg);
			width: 45px;
			/* border: 1px solid #ff9900; */
			padding: 10px;
			border-radius: 50%;
			height: 45px;
			border: 1px solid $jacq-black;
			margin-bottom: 50px;
			cursor: pointer;
			&:hover {
				background: $jacq-black;
				text#_ {

					fill: $jacq-white;
				}
			}
		}
	}

	h1 {
		font-size: 60px;
		color: $jacq-black;
		margin: 0;
	}

	.socials {
		font-size: 25px;
		text-align: right;
		width: 100%;
		bottom: 0;
		position: absolute;

		a {
			color: $jacq-black;
			margin-left: 10px;
			&:before{
				display: none;
			}

			&:hover {
				color: $jacq-green;
			}
		}

	}

}
.blockCertifs .row {
	font-size: 28px;
  }

.g-content {
	a {
		color: $jacq-black;
		position: relative;
		overflow: hidden;

		&::before {
			content: "";
			width: 100%;
			background: $jacq-black;
			height: 1px;
			left: 0;
			position: absolute;
			bottom: 4px;
			transition: left 2s, width 2s;
			-webkit-transition: left 2s, width 2s;
			-moz-transition: left 2s, width 2s;
			-ms-transition: left 2s, width 2s;
			-o-transition: left 2s, width 2s;
		}

		&:hover::before {
			left: 100%;
			width: 0%;
		}
	}

	background: $jacq-gray-light;
	padding-top: 150px;
	padding-bottom: 150px;
	margin-bottom: -200px;



	.imgfull {
		img {
			position: absolute;
			max-width: 846px;
			max-height: 500px;
			object-fit: cover;
			left: 0;
		}

		.alignright {
			img {
				right: 0;
				left: auto;
			}
		}

		.alignleft {
			img {
				left: 0;
			}
		}
	}

	.g-left-text {
		p {
			width: 100%;
			max-width: 430px;
		}
	}

	.img-doble-left img {
		width: 100%;
		height: 380px;
		max-height: 100%;
		object-fit: cover;
		/* max-height: 480px; */
	}


	.g-right-text {
		p {
			width: 100%;
			max-width: 396px;
			margin-left: auto;
		}
	}




}

// Technology page

.technology {
	.hookright {
		max-width: 946px;
		background: $jacq-black;

		img {
			position: absolute;
			top: 0;
			max-height: 688px;
			object-fit: cover;
			width: 100%;
		}
	}



	.hookleft {
		background-color: $jacq-green !important;

		.innerHook {
			background-color: $jacq-green !important;
		}

		.container {
			h1 {
				color: $jacq-black;
			}
		}
	}
}

.technologyHook {
	.container {
		justify-content: flex-start;

		.innerSubHook {
			max-width: calc(100% - 946px);
			background: #f2f2f2;
			width: 100%;
			padding: 5%;

			div {
				width: 100%;
				max-width: 487px;

				margin-left: auto;

				p {
					font-size: 28px !important;
					line-height: 1em;
				}
			}
		}
	}

}

.techcontent {
	background: initial;
	@media (min-width:1400px) {
		margin-top: 100px;
	}
}

div.tech-block:nth-child(odd) {
	flex-direction: row-reverse;

	.tech-text-group h2,
	.tech-text-group .tech-text {
		padding-left: 30%;
		width: 100%
	}

}

div.buttonsActions:nth-child(even) {
	margin-left: 25%;
}


.tech-block {
	margin-bottom: 150px;

	.tech-text-group h2,
	.tech-text-group .tech-text {
		width: 70%;

	}
}

// .referencesPage {

// 	.hookleft {
// 		background-color: $jacq-gray-light !important;
// 		position: relative;
// 		left: 0;
// 		height: 596px !important;

// 		.innerHookBottom {
// 			background-color: $jacq-gray-light !important;
// 			height: 100px;
// 			bottom: -100px !important;
// 			width: 100%;
// 		}

// 		.innerHook {
// 			background-color: $jacq-gray-light !important;
// 		}

// 		.container {
// 			//width: calc(1170px - 946px) !important;

// 			h1 {
// 				margin: 0;
// 			}
// 		}
// 	}
// }

// .contactPage {

// 	.hookleft {
// 		background-color: $jacq-black !important;
// 		position: relative;
// 		left: 0;
// 		height: 596px !important;

// 		.innerHookBottom {
// 			background-color: $jacq-black !important;
// 			height: 100px;
// 			bottom: -100px !important;
// 			width: 100%;
// 		}

// 		.innerHook {
// 			background-color: $jacq-black !important;
// 		}

// 		.container {
// 			//width: calc(1170px - 946px) !important;

// 			h1 {
// 				margin: 0;
// 				color: $jacq-white;
// 			}
// 		}
// 	}

// 	.hookright {
// 		background: $jacq-green !important;
// 		justify-content: center;

// 		.container {
// 			width: calc(100%) !important;


// 			h2 {
// 				font-size: 65px;
// 				width: 100%;
// 				max-width: 380px;
// 				margin: 0;
// 				color: $jacq-black;
// 				line-height: 65px;
// 				padding-top: 65px;
// 				margin-left: 150px;
// 			}
// 		}
// 	}
// }

#page-contact {
	margin-top: 150px;
}

.objectBoxImg {
	position: relative;
	width: 100%;
	overflow: hidden;

	&::after {
		content: '';
		display: block;
		padding-bottom: 100%;
	}

	img {
		position: absolute;
		width: 100%;
		height: 100%;
	}
}

.page-activite h1 {
	font-size: 60px;
	color: #3c3c3b;
	margin: 0;
}

.post-content h1 {
	font-size: 60px;
	color: #3c3c3b;
	margin: 0;
	width: 100%;
	max-width: initial;
}

#page-content {
	margin-top: 265px;
}

.within {
	transform: translateY(-1.5em);
	font-size: .8em;
	color: #0f0;
}

h3.gform_title {
	font-weight: initial;
	color: $jacq-black;
	font-size: 44px;
	margin-bottom: 50px;
}


input#gform_submit_button_1 {
	display: inline-block;
	width: auto;
	color: $jacq-black;
	background-color: transparent;
	border-radius: 9999px;
	-webkit-box-shadow: none;
	box-shadow: none;
	text-decoration: none;
	padding: calc(0.667em + 2px) calc(1.333em + 2px);
	font-size: 1.125em;
	border: 1px solid $jacq-black;

	&:hover {
		border: 1px solid $jacq-black !important;
		background: $jacq-black;
		color: $jacq-white
	}
}

.selector {
	display: inline-block;
	margin-right: 40px;
	cursor:pointer;
}

#reload {
	span {
		display: inline-block;
		width: auto;
		overflow: hidden;
		position:relative;
		font-size:14px;
		cursor:pointer;
		margin-bottom:20px;

		&::after {
			content: "";
			width: 100%;
			background: $jacq-black;
			height: 1px;
			left: 0;
			position: absolute;
			bottom: 4px;
			transition: left 2s, width 2s;
			-webkit-transition: left 2s, width 2s;
			-moz-transition: left 2s, width 2s;
			-ms-transition: left 2s, width 2s;
			-o-transition: left 2s, width 2s;
		}

		&:hover {
			
				&::after {
					background: $jacq-black;
					left: 100%;;
				}
			

		}

	}
}