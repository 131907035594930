//
// Template Styles
// --------------------------------------------------

#main {
    padding-top: 110px; // Header height 
    z-index: 15;
}


.parcer-link {
    display: flex;
    align-items: center;
    img {
        margin-right: 15px;
        width: 115px;
        min-width: 115px;
    }
}
.parcer-namer {
    font-size: 22px;
    line-height: 1.2em;
}