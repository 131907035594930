//
// WooCommerce Styles
// --------------------------------------------------

.woocommerce {
    .single_add_to_cart_button,
    #place_order,
    .checkout-button {
        background-color: $color-link !important;
        border-radius: 0 !important;
        &:hover {
            background-color: $color-link-hover !important;
        }
        &:focus {
            outline: none;
        }
    }
    .related.products {
        margin-top: $margin-base;
    }
    .onsale {
        background-color: $color-primary !important;
    }
    .woocommerce-info {
        border-top-color: $color-primary;
        &:before {
            color: $color-primary;
        }
    }
    .widget_price_filter {
        .ui-slider {
            .ui-slider-range {
                background-color: $color-primary;
            }
            .ui-slider-handle {
                background-color: $color-primary;
            }
        }
        .price_slider_wrapper {
            .ui-widget-content {
                background-color: #ebe9eb;
            }
        }
    }
    .product {
        .summary {
            .cart {
                .quantity {
                    .input-text {
                        padding: 5px;
                    }
                }
            }
            .product_meta {
                span {
                    display: block;
                    span {
                        display: inline;
                    }
                }
            }
        }
    }
    .cart-collaterals {
        margin-top: $margin-base;
    }
    .woocommerce-pagination {
    	.page-numbers {
    		border: 0 !important;
    		li {
    			display: inline-block !important;
    			border-right: 0 !important;
    			float: none !important;
    			a, span {
    				color: white !important;
    				text-decoration: none !important;
    				background-color: $color-gray-dark !important;
    				cursor: pointer !important;
    				padding: 5px 13px !important;
    				display: block !important;
    				font-size: 14px !important;
    				line-height: inherit !important;
    				&:hover {
    					background-color: $color-primary !important;
    					color: #ffffff !important;
    				}
    				&.current {
    					background-color: $color-primary !important;
    					color: #ffffff !important;
    				}
    			}
    		}
    	}
    }
}
