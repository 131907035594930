//
// {Res}ponsive Styles
// --------------------------------------------------

@media (min-width: 1200px) {
	.container {
		max-width: 1170px;
	}

	#header {
		#menu-mobile-trigger {
			display: none;
		}


	}



}

@media (max-width:1600px) {
	.teampage, .referencesPage {
		.hookright {
			max-width: 50%;
		}
	}
}

@media (max-width: 1199px) {
	#header {
		#menu-mobile-trigger {
			display: block;
		}
		#menu-main-container {
			display: none !important;
		}
	}
}

@media (max-width: 1299px) {

	#menu{
		position:relative;
	}

	.mm-ocd {
		.mm-spn {
			background-color: #3c3c3b;
			color: white;
			font-size: 18px;
		}
		.current-menu-item,
		.current-menu-ancestor,
		.current-menu-parent,
		.current-product-ancestor,
		.current-product_cat-ancestor,
		.current-page-ancestor {
			>a {
				color: $jacq-green;
			}
		}
	}

	// .mm-spn a, .mm-spn span {
	// 	padding: 20px 20px !important;
	// }

	.mm-spn.mm-spn--navbar:after {
		opacity: 1;
		font-weight: bold;
		top: 2px;
	}

	// #homeVideo {
	// 	margin-top: -110px;
	// }

	#main {
		// margin-top: 135px;
	}

	#fast-access {
		flex-wrap: wrap;

		a {
			width: 31%;
			margin: 1%;
		}
	}

	#footer {
		ul {
			li {
				a {
					margin-right: 20px;
				}
			}
		}
	}

	// .officepage,
	// .referencesPage,
	// .page-activite,
	// .teampage,
	// .technology,
	// .page-section {
	// 	margin-top: -110px !important;
	// }

	.acfb_tabs_block {
		ul {
			li.active {
				a {
					line-height: 44px;
					font-size: 40px !important;
				}
			}
		}
	}

	.alignfull {
		height: auto !important;
		min-height: 250px !important;
	}

	#hook {
		.hookleft {
			width: 50%;
		}
	}

	.teampage {
		.hookleft {
			.container {
				width: 100% !important;
			}
		}

		.hookright {
			img {
				position: relative;
				top: 0;
				max-height: 100%;
				height: 100%;
				width: 100%;
				object-fit: cover;
			}
		}
	}

	.technologyHook {
		.container {
			.innerSubHook {
				max-width: 50%;
			}
		}
	}

	.officepage {
		.hookright {
			max-width: 50%;
			background: #f2f2f2;

			img {
				position: relative;
				top: 0;
				width: 100%;
				-o-object-fit: cover;
				object-fit: cover;
				height: 100%;
				max-height: none;
			}
		}
	}

	.referencesPage {
		.hookleft {
			.container {
				width: 100% !important;
			}
		}

		.hookright {
			img {
				position: relative;
				top: 0;
				width: 100%;
				-o-object-fit: cover;
				object-fit: cover;
				height: 100%;
				max-height: none;
			}
		}
	}

	.innerHook {
		display: none;
	}

	.innerHookBottom {
		display: none;
	}

	.g-content {
		.imgfull {
			img {
				position: relative;
				max-width: 100%;
				max-height: 100%;

			}
		}
	}

	#singleContent {
		margin-top: 0;
		margin-bottom: 95px;
	}

	#page-content {
		margin-top: 0;
	}

	.contactPage {
		.hookright {
			.container {
				h2 {
					margin-left: 0;
				}
			}
		}
	}








}

@media (max-width: 991px) {

	#fast-access {
		flex-direction: initial;
		flex-wrap: wrap;
	}

	#home-intro {
		margin: 0 0 0 0;
		padding: 70px 0 70px;
	}

	#fast-access {
		a {
			font-size: 25px;
			margin: 5%;
			width: 20%;

			span {
				opacity: 1 !important;
			}
		}
	}


	#fast-access {
		a {
			font-size: 25px;
			margin: 5%;

			span {
				opacity: 1 !important;
			}
		}
	}

	#quick-access {
		margin: 6rem auto 6rem;
		ul {
			li {
				padding-bottom: 10px;
				a {
					font-size: 22px!important;
					&:hover {
						font-size: 25px!important;
					}
				}
			}
		}
	}

	#footer {
		ul {
			flex-direction: column;
			align-items: center;

			li {
				margin-bottom: 20px;

				a {
					margin-right: 0;

				}
			}
		}

		.align-right {
			justify-content: center;
		}

		#footer-top {
			.row {
				align-items: flex-start;
			}
		}
	}

	#main {
		// margin-top: 135px;
	}



	#hook {
		flex-direction: column;


		.hookleft {
			height: auto;
			width: 100%;
			// padding: 10%;
			height: auto !important;
			// padding-left: 0;
			// padding-right: 0;
			padding: 0;

			.container {
				width: 100% !important;

				h1 {
					font-size: 10vw;
					margin: 0;
					max-width: 100%;
				}
			}
		}

		.hookright {
			max-width: 100% !important;
			overflow: hidden;
			// max-height: 400px;
			// min-height: 300px;
			height: auto;
			&.text {
				padding: 2.5rem 0 0;
			}

			.picture-homepage {
				max-width: 100% !important;
			}

			a {
				margin-top: 35px;
			}
		}


	}
	.home #hook .hookright {
		a {
			margin-top: 0;
			margin-bottom: 1rem;
		} 
		img {
			max-height: 270px;
		}
	} 


	.innerHookBottom {
		display: none;
	}

	.objectBox {
		width: 29% !important;
		margin: 2% !important;
		margin-bottom: auto !important;
	}

	#team {
		height: auto;
	}

	#teamRow {
		.row {
			margin-bottom: 0;
		}

		// #director {
		// 	div {
		// 		&.objectBox {
		// 			&:nth-child(1) {
		// 				width: 50% !important;
		// 				margin-left: 10%
		// 			}

		// 		}

		// 	}

		// }

	}




	#reload {
		margin-top: 50px;
	}

	#filters {
		margin-bottom: 50px;
		margin-top: 0
	}

	.selector {
		display: inline-block;
		margin-right: 0;
		width: 50%;
		margin-bottom: 10px;
	}

	.contactPage {
		.hookright {
			height: auto !important;
			min-height: 100px !important;

			.container {
				h2 {
					font-size: 9vw;
					width: 100%;
					max-width: 100%;
					margin: 0;
					color: #3c3c3b;
					line-height: 9vw;
					padding-top: 0;
					margin-left: 0;
				}
			}
		}
	}

	.officepage {
		.hookleft {
			// max-height: 400px;
			height: auto;
			img {
				margin-left: auto;
				margin-right: auto;
			}
		}
	}

	// .officepage,
	// .referencesPage,
	// .page-activite,
	// .teampage,
	// .technology,
	// .page-section {
	// 	margin-top: -110px !important;
	// }

	#page-content {
		margin-top: 0;
	}

	.post-content h1 {
		font-size: 9vw;
		width: 100%;
		max-width: 100%;
		margin: 0;
		color: #3c3c3b;
		line-height: 9vw;
		padding-top: 0;
		margin-left: 0;
	}

	#singleContent {
		margin-top: 0;
	}

	.pl-6,
	.px-6 {
		padding-top: 6rem !important;
		padding-left: 0 !important;
	}

	.g-content {
		.g-right-img {
			height: auto !important;
		}

		.imgfull {
			img {
				position: relative;
				max-width: 100%;
				max-height: none;
				-o-object-fit: cover;
				object-fit: cover;
				left: 0;
			}
		}

	}

	.alignfull {
		height: auto !important;
		min-height: 250px !important;
	}

	.technologyHook {
		.container {
			justify-content: flex-start;

			.innerSubHook {
				max-width: 100%;
				background: #f2f2f2;
				width: 100%;
				padding: 5%;

				div {
					width: 100%;
					max-width: 100%;

					margin-left: auto;

					p {
						font-size: 28px !important;
						line-height: 1em;
					}
				}
			}
		}

	}

	.acfb_tabs_block {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		flex-direction: column;
	}

	.acfb_tabs_block ul {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		width: 100%;
	}

	.acfb_tabs_block .acfb_tab_content {
		padding: 20px;
		width: 100%;
	}

}

@media (max-width: 767px) {

	.accordion {
		.active {
			font-size: 32px;
			line-height: 32px;
		}
	}
	.panel {
		padding: 0 0 !important;
	}

	#fast-access {
		flex-direction: initial;
		flex-wrap: wrap;
	}

	.actuBox {
		position: relative !important;
		width: 90% !important;
		margin: 5%;
	}

	#fast-access {
		a {
			font-size: 25px;
			margin: 5%;

			span {
				opacity: 1 !important;
			}
		}
	}

	.actuBox {
		position: relative !important;
		width: 90% !important;
		margin: 5%;
	}

	#fast-access {
		a {
			font-size: 25px;
			margin: 5%;

			span {
				opacity: 1 !important;
			}
		}
	}

	#footer {
		ul {
			flex-direction: column;
			align-items: center;

			li {
				margin-bottom: 20px;

				a {
					margin-right: 0;

				}
			}
		}

		.align-right {
			justify-content: center;
		}
	}

	#main {
		// margin-top: 135px;
	}

	.row {
		margin: 0 !important;
		min-height: auto !important;
	}

	#hook {
		flex-direction: column;


		.hookleft {
			height: auto;
			width: 100%;
			// padding: 10%;
			height: auto !important;
			padding-left: 0;
			padding-right: 0;

			.container {
				width: 100% !important;

				h1 {
					font-size: 10vw;
					margin: 0;
					max-width: 100%;
				}
			}
		}

		.hookright {
			max-width: 100% !important;
			overflow: hidden;
			// max-height: 400px;
			// min-height: 300px;
			height: auto;

			.picture-homepage {
				max-width: 100% !important;
			}

			a {
				margin-top: 35px;
			}
		}
	}

	.innerHookBottom {
		display: none;
	}

	.objectBox {
		width: 100% !important;
		margin: 0 !important;
		margin-bottom: 50px !important;
	}

	#team {
		height: auto;
	}

	#director {
		flex-direction: column !important;
		margin-bottom: 50px !important;
		.objectBox{
			width:100%!important;
		}
	}

	

	#reload {
		margin-top: 50px;
	}

	#filters {
		margin-bottom: 50px;
		margin-top: 0
	}

	.selector {
		display: inline-block;
		margin-right: 0;
		width: 50%;
		margin-bottom: 10px;
	}

	.contactPage {
		.hookright {
			height: auto !important;
			min-height: 100px !important;

			.container {
				h2 {
					font-size: 9vw;
					width: 100%;
					max-width: 100%;
					margin: 0;
					color: #3c3c3b;
					line-height: 9vw;
					padding-top: 0;
					margin-left: 0;
				}
			}
		}
	}

	// .officepage,
	// .referencesPage,
	// .page-activite,
	// .teampage,
	// .technology,
	// .page-section {
	// 	margin-top: -110px !important;
	// }

	#page-content {
		margin-top: 0;
	}

	.post-content h1 {
		font-size: 9vw;
		width: 100%;
		max-width: 100%;
		margin: 0;
		color: #3c3c3b;
		line-height: 9vw;
		padding-top: 0;
		margin-left: 0;
	}

	#singleContent {
		margin-top: 0;
	}

	.pl-6,
	.px-6 {
		padding-top: 6rem !important;
		padding-left: 0 !important;
	}

	.g-content {
		.g-right-img {
			height: auto !important;
		}

		.imgfull {
			img {
				position: relative;
				max-width: 100%;
				max-height: none;
				-o-object-fit: cover;
				object-fit: cover;
				left: 0;
			}
		}

	}

	.alignfull {
		height: auto !important;
		min-height: 250px !important;
	}

	.technologyHook {
		.container {
			justify-content: flex-start;

			.innerSubHook {
				max-width: 100%;
				background: #f2f2f2;
				width: 100%;
				padding: 5%;

				div {
					width: 100%;
					max-width: 100%;

					margin-left: auto;

					p {
						font-size: 28px !important;
						line-height: 1em;
					}
				}
			}
		}

	}

	.acfb_tabs_block {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		flex-direction: column;
	}

	.acfb_tabs_block ul {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		width: 100%;
	}

	.acfb_tabs_block .acfb_tab_content {
		padding: 20px;
		width: 100%;
	}

}

@media (max-width: 576px) {

	#home-intro {
		padding: 4rem 0;
		.content-text {
			font-size: 26px;
		}
	}

	#fast-access {
		flex-direction: column;
	}

	#quick-access {
		margin: 4rem auto 4rem;
	}

	.actuBox {
		position: relative !important;
		width: 90% !important;
		margin: 5%;
	}

	#fast-access {
		a {
			font-size: 25px;
			margin: 5%;

			span {
				opacity: 1 !important;
			}
		}
	}

	#footer {
		ul {
			flex-direction: column;
			align-items: center;

			li {
				margin-bottom: 20px;

				a {
					margin-right: 0;

				}
			}
		}

		.align-right {
			justify-content: center;
		}
	}

	#main {
		// margin-top: 135px;
	}

	.row {
		margin: 0 !important;
		min-height: auto !important;
	}

	.home {
		#hook {
			.hookleft {
				padding: 10%;
			}
		}
	}

	#hook {
		flex-direction: column;


		.hookleft {
			height: auto;
			width: 100%;
			// padding: 10%;
			height: auto !important;
			// padding-left: 0;
			// padding-right: 0;
			&.text {
				padding: 10%;
			}

			.container {
				width: 100% !important;

				h1 {
					font-size: 10vw;
					margin: 0;
					max-width: 100%;
				}
			}
		}

		.hookright {
			max-width: 100% !important;
			overflow: hidden;
			// max-height: 400px;
			// min-height: 300px;
			height: auto;
			&.text {
				padding: 10%;
			}

			.picture-homepage {
				max-width: 100% !important;
			}

			a {
				margin-top: 35px;
			}
		}
	}

	.innerHookBottom {
		display: none;
	}

	.objectBox {
		width: 100% !important;
		margin: 0 !important;
		margin-bottom: 50px !important;
	}

	#team {
		height: auto;
	}

	#director {
		flex-direction: column !important;
		margin-bottom: 50px !important;
	}

	#reload {
		margin-top: 50px;
	}

	#filters {
		margin-bottom: 50px;
		margin-top: 0
	}

	.selector {
		display: inline-block;
		margin-right: 0;
		width: 50%;
		margin-bottom: 10px;
	}

	.contactPage {
		.hookright {
			height: auto !important;
			min-height: 100px !important;

			.container {
				h2 {
					font-size: 9vw;
					width: 100%;
					max-width: 100%;
					margin: 0;
					color: #3c3c3b;
					line-height: 9vw;
					padding-top: 0;
					margin-left: 0;
				}
			}
		}
	}

	// .officepage,
	// .referencesPage,
	// .page-activite,
	// .teampage,
	// .technology,
	// .page-section {
	// 	margin-top: -110px !important;
	// }

	#page-content {
		margin-top: 0;
	}

	.tech-text-group *{
		margin-left:0!important;
		padding-left:0!important;
	}

	.showMoreACF {
		padding: 10px!important;
	}

	.post-content h1 {
		font-size: 9vw;
		width: 100%;
		max-width: 100%;
		margin: 0;
		color: #3c3c3b;
		line-height: 9vw;
		padding-top: 0;
		margin-left: 0;
	}

	#singleContent {
		margin-top: 0;
	}

	.pl-6,
	.px-6 {
		padding-top: 6rem !important;
		padding-left: 0 !important;
	}

	.g-content {
		.g-right-img {
			height: auto !important;
		}

		.imgfull {
			img {
				position: relative;
				max-width: 100%;
				max-height: none;
				-o-object-fit: cover;
				object-fit: cover;
				left: 0;
			}
		}

	}

	.alignfull {
		height: auto !important;
		min-height: 250px !important;
	}

	.technologyHook {
		.container {
			justify-content: flex-start;

			.innerSubHook {
				max-width: 100%;
				background: #f2f2f2;
				width: 100%;
				padding: 5%;

				div {
					width: 100%;
					max-width: 100%;

					margin-left: auto;

					p {
						font-size: 28px !important;
						line-height: 1em;
					}
				}
			}
		}

	}

	.acfb_tabs_block {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		flex-direction: column;
	}

	.acfb_tabs_block ul {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		width: 100%;
	}

	.acfb_tabs_block .acfb_tab_content {
		padding: 20px;
		width: 100%;
	}
}