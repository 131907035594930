//
// Font Faces Styles
// @help: url('../webfonts/xxx)
// --------------------------------------------------

//Font Awesome
@font-face {
  font-family: "Font Awesome 6 Brands";
  src: url("../webfonts/fontawesome/fa-brands-400.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-brands-400.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 6 Duotone";
  src: url("../webfonts/fontawesome/fa-duotone-900.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-duotone-900.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 6 Light";
  src: url("../webfonts/fontawesome/fa-light-300.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-light-300.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 6 Regular";
  src: url("../webfonts/fontawesome/fa-regular-400.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-regular-400.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 6 Sharp";
  src: url("../webfonts/fontawesome/fa-sharp-900.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-sharp-900.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 6 Solid";
  src: url("../webfonts/fontawesome/fa-solid-900.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-solid-900.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 6 Thin";
  src: url("../webfonts/fontawesome/fa-thin-100.woff2") format("woff2"),
  url("../webfonts/fontawesome/fa-thin-100.ttf") format("truetype");
}

@font-face {
  font-family: 'suisse_intlregular';
  src: url('../webfonts/suisseintl-regular_1-webfont.woff2') format('woff2'),
       url('../webfonts/suisseintl-regular_1-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}