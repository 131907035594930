//
// Header Styles
// --------------------------------------------------

#header {
	width: 100%;
	background-color: $jacq-black;
	height: 110px;
	position: fixed;
	left: 0;
	top: 0;
	display: flex;
	z-index: 20;
	transition: all 0.4s linear;

	// &.sticky {
	// 	padding: 5px 0;
	// 	#header-extras {
	// 		opacity: 0;
	// 		margin-top: -40px;
	// 	}
	// 	#menu {
	// 		margin-top: 20px;
	// 	}
	// }

	#header-content {
		display: flex;
		align-content: center;
		flex-wrap: nowrap;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		align-items: center;
		height: 100%;
	}

	#menu-mobile-trigger {
		font-size: 31px;
		display: none;

		.trigger-bar {
			display: block;
			width: 25px;
			height: 2px;
			transition: all 0.2s linear;
			position: relative;
			background-color: white;

			&:not(:first-child) {
				margin-top: 6px;
			}
		}
	}

	#logo {
		float: left;
		width: 206px;
		min-width: 206px;
		a {
			display: block;
			color: $jacq-green;
			font-size: 30px;
			img {
				width: 206px;
				min-width: 206px;
			}
		}
	}

	#search-action {
		.input-group {
			display: flex;

			.form-control {
				width: 250px;
			}
		}
	}

	#menu {
		transition: all 0.4s linear;
		@media (min-width: 1200px) {
			height: 110px;
		}
		// float: right;

		#menu-main-container {
			display: inline-block;
			width: 100%;

			ul {
				margin: 0;
				padding: 0;
				list-style: none;
				text-align: right;
				float: right;

				li {
					float: left;
					position: relative;
					margin-right: 55px;
					height: 110px;
					display: flex;
					align-items: stretch;

					a {
						font-size: 18px;
						display: block;
						color: #fff;
						display: flex;
						height: 100%;
						align-items: center;

						&:hover {
							color: $color-link-hover;
						}
					}

					&:last-child {
						margin-right: 0;
					}

					&.current-menu-item,
					&.current-menu-ancestor,
					&.current-menu-parent,
					&.current-product-ancestor,
					&.current-product_cat-ancestor,
					&.current-page-ancestor {
						>a {
							color: $jacq-green;
						}

						// .sub-menu {
						// 	li {
						// 		a {
						// 			color: $color-primary;
						// 		}
						// 	}
						// }
					}

					.sub-menu {
						opacity: 0;
						height: 0;
						padding: 0;
						position: absolute;
						text-align: left;
						margin-left: -30px;
						transform: scale(1) translateY(20px);
						transition: transform 0.25s ease-in-out, opacity 0.15s ease-in-out;
						z-index: 10;
						overflow: hidden;
						display: flex;
						align-items: center;

						li {
							white-space: nowrap;
							margin: 0;
							float: none;
							height: auto;

							a {
								display: block;
								color: $jacq-black;
								padding: 18px 22px;
								@media (max-width: 1500px)  {
									padding: 18px 16px;
									font-size: 16px;
								}
								&:hover{
									color: #ffffff;
								}
								
							}

							&:last-child {
								margin-bottom: 0;
							}

							&.current-menu-item,
							&.current-menu-ancestor,
							&.current-menu-parent,
							&.current-product-ancestor,
							&.current-product_cat-ancestor,
							&.current-page-ancestor {
								a {
									color: white;
								}
							}
						}
					}

					&.menu-item-has-children {
						
						&:hover {
							// &:after {
							// 	content: '';
							// 	position: absolute;
							// 	left: 50%;
							// 	transform: translateX(-50%);
							// 	width: 200%;
							// 	height: 40px;
							// 	background-color: red;
							// 	bottom: -5px;
							// }

							.sub-menu {
								height: auto;
								opacity: 1;
								transform: scale(1) translateY(0);
								padding-top: 0;
								top: 110px;
								background: $jacq-green;
								width: 100vw;
							}
						}

						&.active {
							// &:after {
							// 	transform: translateY(-50%) rotate(90deg) !important;
							// }

							.sub-menu {
								height: auto !important;
								opacity: 1 !important;
								transform: scale(1) translateY(0) !important;
								padding-top: 0 !important;
								top: 110px !important;
								background: $jacq-green !important;
								width: 100vw !important;
							}
						}
					}
				}
			}
		}
	}

	@media (min-width:1200px) {
		#menu #menu-main-container #menu-principal {
			padding-left: 30px;
			background: #3c3c3b;
			position: relative;
			&:after {
				content: "";
				background: #3c3c3b;
				position: absolute;
				left: 100%;
				width: 100%;
				height: 110px;
				top: 0;
			}
			.sub-menu {
				z-index: -1;
				opacity: 1;
				transform: translateY(-110px);
				transition: transform .3s ease-in-out, z-index 0s ease-in .3s;
			}
			.menu-item-has-children {
				&:hover {
					&:after {
						content: '';
						position: absolute;
						left: 50%;
						width: 200%;
						height: 40px;
						bottom: 0;
						transform: translateX(-50%);
					}
					.sub-menu {
						height: auto;
						transform: translateY(0px);
						padding-top: 0;
						top: 110px;
						background: $jacq-green;
						width: 100vw;
						z-index: 1;
					}
				}
				&.active {
					&:after {
						content: '';
						position: absolute;
						left: 50%;
						width: 200%;
						height: 40px;
						bottom: 0;
						transform: translateX(-50%);
					}
					.sub-menu {
						height: auto !important;
						transform: translateY(0px) !important;
						padding-top: 0 !important;
						top: 110px !important;
						background: $jacq-green !important;
						width: 100vw !important;
						z-index: 1 !important;
					}
				}
			}
		}
	}

	#header-extras {
		position: absolute;
		top: 0;
		right: 15px;
		transition: all 0.4s linear;
		margin-top: 0;

		.extra {
			display: inline-block;
			margin-right: 25px;

			&:last-child {
				margin-right: 0;
			}
		}

		#header-cart {
			i {
				color: #ffffff;
				margin-right: 6px;
			}
		}

		#language-switcher {
			.languages-list {
				margin: 0;
				padding: 0;
				list-style: none;

				li {
					display: inline-block;
					border-right: 1px solid $color-primary;
					margin-right: 7px;
					padding-right: 7px;

					.lang {
						text-transform: uppercase;
						display: block;
						color: $color-primary;

						&.active {
							color: #ffffff;
							font-weight: lighter;
						}
					}

					&:last-child {
						border-right: 0;
						margin-right: 0;
						padding-right: 0;
					}
				}
			}
		}
	}
}