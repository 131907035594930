//
// Footer Styles
// --------------------------------------------------

#footer {
	background-color: $jacq-black;
	color: #ffffff;
	font-size: 14px;
	padding: 40px 0;
	margin-top:200px;
	@media (min-width:992px) {
		min-height: 102px;
		max-height: 102px;
		padding: 20px 0;
		display: flex;
		align-items: center;
		.col-12 {
			display: flex;
			align-items: center;
		}
	}

	.footer-bloc-title {
		margin-top: 0;
	}

	ul {
		padding: 0;
		margin: 0;
		list-style: none;
		display:flex;
		li {
			a {
				color: #ffffff;
				margin-right:100px;
				font-size:$text-footer;
				&:hover {
					color: $color-primary;
				}
			}
		}
	}


	#menu-legal{

		li{
			a{
				margin-right:0;
				font-size:14px;
				&:hover {
					color: $color-primary;
				}
			}
		}
	}

	.align-right{
		display:flex;
		justify-content:flex-end;
	}

	.logo-footer{
		width:35px
	}

	

	
}
